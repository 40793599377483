<template>
	<section class="tariffs">
		<div class="container">
			<div class="tariffs__wrapper">
				<Breadcrumb :pages="pages" />

				<div class="tariffs__body">
					<div class="tariffs__header">
						<h1 class="tariffs__title">{{ $t('web.pricing') }}</h1>
						<h2>{{ $t('web.tariff_desc') }}</h2>
					</div>

					<div class="tariffs__main">
						<TariffItem />

						<div class="tariffs__prices">
							<div class="tariffs__prices-title">
								{{ $t('web.prices') }}
							</div>
							<div class="tariffs__prices-body">
								<div v-if="isAgency">
									<div
										v-for="item in prices"
                    :key="item.from_count"
										class="tariffs__prices-item"
									>
										<p>
											{{ item.price }}
											AED
										</p>
										<span>{{ $t('web.per_month') }}</span>
										<div>
                      {{ item.to_count ? item.from_count.toString() + ' - ' + item.to_count.toString() : item.from_count.toString() + '+'}}
                      {{ $t('web.agents') }}
                    </div>
									</div>
								</div>
                <div v-else>
                  <div class="tariffs__prices-item">
                    <p>{{ prices[0]?.price }} AED </p>
                    <span>{{ $t('web.per_month') }}</span>
                  </div>
                </div>
								<div class="tariffs__prices-info">
									<p v-if="discounts && discounts.length > 0">
                    <template v-for="item in discounts" :key="item.from_months">
                      <span>
                        {{ item.to_months ? item.from_months.toString() + ' - ' + item.to_months.toString() : item.from_months.toString() + '+'}}
                        {{ $t('web.months') }} - {{ item.discount }}%
                      </span>
                      {{ $t('web.discount_total_amount') }}
                      <br />
                    </template>
									</p>
									<p>*{{ $t('web.tariff_info_text') }}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'
import TariffItem from '@/modules/broker/components/TariffItem.vue'
import { money } from '@/enums/moneyСollections.js'
import { createRequestBroker } from '@/api/requestBuilder'
import requestBrokerConfigs from '@/api/requestBrokerConfigs'

export default {
	name: 'Tariffs',
	components: { TariffItem, Breadcrumb },
  data() {
    return {
      prices: [],
      discounts: []
    }
  },
	methods: {
		getCurrenctMoney(price) {
			return price[money[this.$store.state.activeMoney]]
		}
	},
	beforeMount() {
		createRequestBroker(requestBrokerConfigs.GETTariffsList).then((response) => {
      this.prices = response.data.prices
      this.discounts = response.data.discounts
			// console.log('response ', response)
		})
	},
	computed: {
		isAgency() {
      return this.$store.state.broker.role === 'agency'
    },
		pages() {
			return {
				first: {
					title: this.$t('general.home'),
					srcName: 'MainRoute'
				},
				inner: [
					{
						title: this.isAgency ? this.$t('web.agency_profile_title') : this.$t('web.agent_profile_title'),
						srcName: 'BrokerProfile'
					}
				],
				current: this.$t('web.pages_agency_tariffs')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.tariffs {
	margin-bottom: 80px;
	&__wrapper {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__body {
		display: flex;
		flex-direction: column;
		gap: 40px;
	}
	&__header {
		display: flex;
		flex-direction: column;
		gap: 20px;
		h1 {
			font-size: 28px;
			font-weight: 700;
			line-height: 120%;
			margin: 0;
		}
		h2 {
			font-size: 18px;
			font-weight: 700;
			line-height: normal;
			margin: 0;
		}
	}
	&__main {
		display: flex;
		gap: 40px;
		align-items: flex-start;
	}
	&__prices {
		max-width: 343px;
		width: 100%;
		&-title {
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 3px;
		}
		&-body {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
		&-item {
			display: flex;
			gap: 5px;
			align-items: center;
			width: 100%;
			padding: 10px 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
			& > p {
				margin: 0;
				font-size: 14px;
				font-weight: 700;
				line-height: normal;
				color: var(--primary-1, #c99d56);
			}
			& > span {
				font-size: 12px;
				line-height: normal;
			}
			& > div {
				color: var(--secondary-1, #808080);
				font-size: 12px;
				font-weight: 700;
				line-height: normal;
				margin-left: auto;
			}
		}
		&-info {
			font-size: 12px;
			line-height: normal;
			color: var(--secondary-1, #808080);
			display: flex;
			flex-direction: column;
			gap: 14px;
			p {
				margin: 0;
				span {
					color: var(--primary-2, #b98024);
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		&__main {
			flex-direction: column;
			align-items: center;
		}
	}
	@media screen and (max-width: 479px) {
		&__header {
			h1 {
				font-size: 22px;
			}
			h2 {
				font-size: 14px;
			}
		}
	}
}
</style>
