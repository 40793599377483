<template>
	<form @submit.prevent="submit" class="tariff-item">
		<div class="tariff-item__title">{{ $t('web.enterprise') }}</div>
		<div class="tariff-item__conditions">
			<div class="tariff-item__conditions-item">
				<p>{{ $t('web.months') }}</p>
				<or-input
					class="main-type"
					type="number"
					v-model.number="form.months"
					placeholder="0"
				/>
			</div>
			<div class="tariff-item__conditions-item" v-if="isAgency">
				<p>{{ $t('web.agents') }}</p>
				<or-input
					class="main-type"
					type="number"
					v-model.number="form.agents"
					placeholder="0"
          :disabled="form.subscription_ids?.length > 0"
				/>
			</div>
			<div class="tariff-item__conditions-item">
				<p>{{ $t('web.price') }} ({{ symbol }})</p>
				<span>{{ amount }}</span>
			</div>
		</div>
		<div class="tariff-item__btns">
			<or-button type="submit" :height="48" class="tariff-item__btn">
				{{ $t('web.pay') }}
			</or-button>
			<!-- <or-button kind="secondary" :height="48" class="tariff-item__btn">
				{{ $t('web.invoice') }}
			</or-button> -->
		</div>
	</form>
</template>

<script setup>
import {computed, onBeforeMount, ref, watch} from "vue";
import {createRequestBroker} from "@/api/requestBuilder";
import requestBrokerConfigs from "@/api/requestBrokerConfigs";
import {useRoute} from "vue-router";
import {useStore} from "vuex";

const route = useRoute()
const store = useStore()

const form = ref({
  months: null,
  agents: null,
  subscription_ids: null
})
const amount = ref(0)

const symbol = computed(() => {
  // return store.state.activeMoney
  return 'AED'
})

const isAgency = computed(() => {
  return store.state.broker.role === 'agency'
})

let _lastCalculateCallTime = 0;
async function _calculate() {
  const _callTime = Date.now()
  _lastCalculateCallTime = _callTime;
  if (form.value.months && form.value.months > 0
    && ((form.value.agents && form.value.agents > 0) || form.value.subscription_ids)
  ) {
    const response = await createRequestBroker(requestBrokerConfigs.POSTCalculatePaymentData, {
      jsonPayload: {
        months: form.value.months,
        slots: form.value.subscription_ids?.length > 0 ? form.value.subscription_ids.length : form.value.agents
      }
    })
    if (_lastCalculateCallTime > _callTime) return
    amount.value = response.response.amount
  } else {
    amount.value = 0
  }
}

function submit() {
  const { commit } = store

  if (amount.value < 1 && form.value.subscription_ids?.length === 0) {
    commit('showNotif', {
      type: 'error',
      text: 'Invalid agents or months'
    })

    return
  }

  createRequestBroker(requestBrokerConfigs.POSTSendPaymentData, {
    jsonPayload: {
      months: form.value.months,
      slots: form.value.subscription_ids?.length > 0 ? undefined : form.value.agents,
      slot_ids: form.value.subscription_ids?.length > 0 ? form.value.subscription_ids : undefined,
      amount: amount.value
    }
  }).then((response) => {
    const link = response.response.url
    window.location = link
  })
}

onBeforeMount(() => {
  const subscriptionIds = route.query.subscription_ids
  form.value.subscription_ids = subscriptionIds
  if (!Array.isArray(subscriptionIds) && subscriptionIds?.length > 0) {
    form.value.subscription_ids = [subscriptionIds]
    form.value.agents = form.value.subscription_ids.length
  }
  if (!isAgency.value) {
    form.value.agents = 1
  }
})

watch(() => [form.value.agents, form.value.months], async () => {
  await _calculate()
})
</script>

<style lang="scss">
.tariff-item {
	border-radius: 4px;
	border: 1px solid var(--opacity-black-10, rgba(0, 0, 0, 0.1));
	background: var(--opacity-black-2, rgba(0, 0, 0, 0.02));
	max-width: 380px;
	width: 100%;
	padding: 15px 10px;
	min-height: 230px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;

	&__title {
		text-align: center;
		font-size: 22px;
		font-weight: 700;
		line-height: 130%;
		letter-spacing: -0.66px;
	}
	&__conditions {
		gap: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
	}
	&__conditions-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 5px;
		flex: 0 1 90px;

		p {
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			line-height: 21px;
			margin: 0;
		}
		& .main-input.main-type .main-input__content {
			height: 40px;
			text-align: center;
		}
		span {
			color: var(--primary-1, #c99d56);
			text-align: center;
			font-size: 16px;
			font-weight: 700;
			line-height: normal;
			min-height: 40px;
			display: flex;
			align-items: center;
		}
	}
	&__btns {
		display: flex;
		flex-direction: column;
		gap: 10px;
		width: 100%;
	}
	@media screen and (max-width: 479px) {
		&__conditions {
			gap: 10px;
			grid-template-columns: repeat(3, 80px);
		}
	}
}
</style>
